// alterar arquivo: public/index.html
// checar se existe código do google analytics (apagar ou substituir se for o caso)

// alterar arquivos: .firebaserc (conferir nesse se o default e target apontam para o projeto correto do firebase), firebase.json, package.json e info.txt com o nome do projeto no firebase

// color primary: public/assets/switcher/css/color2.css
// color secondary: public/assets/switcher/css/color2.css: 135

export const id_firebase = "qnwMwEmlNA8c2mNw7noj";  // Shopping


export const ids_firebase = [
    "AJaxwEeyRHFT0mJSXszX",  // Canever 0
    "MLVaKMzAJS3hlSdQBWUV",  // GBS 1
    "2Jc5Cv0NmR0zoJKOD7mE",  // Maverick 2
    "10hrB73mIdmXp5QWKOhK",  // P 3
    "OtkwZKeDKX4GYJmcObfu",  // Premium 4
    "8cGlcWDnEIIuUUgQxnmJ",  // Tops 5
    "lHRfo2VvUs9R0hHWptg2",  // AfCustoms 6 
    "hvqxujjW5TNtcDHUOahJ",   // Atacaar 7
    "DOXYBLi87Wefi8PNSjwq"   // Evolution 8
];

    export const href_revenda = [
        "veiculo_canever",
        "veiculo_gbs",
        "veiculo_maverick",
        "veiculo_pissarelli",
        "veiculo_premium",
        "veiculo_tops",
        "veiculo_afcustoms",
        "veiculo_atacaar",
        "veiculo_evolution"
    ];
    